import React, { useState } from "react";
import "./CSS/Login.css";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check email and password against values stored in .env
    const validEmail = process.env.REACT_APP_LOGIN_EMAIL;
    const validPassword = process.env.REACT_APP_LOGIN_PASSWORD;

    if (email === validEmail && password === validPassword) {
      setError("");
      // Login successful
      alert("Login successful!");
      onLogin(); // Call onLogin function passed from Admin component
    } else {
      setError("Invalid email or password.");
    }
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button type="submit">Login</button>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Login;
