import React, { useEffect, useState } from "react";
import "./ListProduct.css";

const ListProduct = () => {
  const [allproducts, setAllProducts] = useState([]);
  const [editProduct, setEditProduct] = useState(null); // State to track the product being edited
  const [loading, setLoading] = useState(false); // State for loading indicator

  const fetchInfo = () => {
    setLoading(true); // Set loading to true when starting fetching data
    fetch("https://main.healthyharvest.fit/api/allproducts")
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data);
        setLoading(false); // Set loading to false after data fetching is done
      });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const removeProduct = async (id) => {
    setLoading(true); // Set loading to true when starting fetching data
    await fetch("https://main.healthyharvest.fit/api/removeproduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    });

    fetchInfo();
  };

  const handleEdit = (product) => {
    setEditProduct({ ...product });
  };

  // Function to handle editing the product details
  const handleSave = async () => {
    setLoading(true); // Set loading to true when starting fetching data
    // Perform update on the server
    await fetch("https://main.healthyharvest.fit/api/updateproduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editProduct),
    });

    // Fetch updated product list
    fetchInfo();

    // Clear edit mode
    setEditProduct(null);
  };

  // Function to handle changes in the product details
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditProduct({ ...editProduct, [name]: value });
  };

  return (
    <div className="listproduct">
      <h1>All Products List</h1>
      <div className="listproduct-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Old Price</p>
        <p>New Price</p>
        <p>Category</p>
        <p>Edit</p> {/* Replace 'Remove' with 'Edit' */}
      </div>
      {loading ? (
        <div className="loading-modal">
          <div className="loader">
            <svg
              className="spinner"
              width="60px"
              height="60px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      ) : (
        <div className="listproduct-allproducts">
          <hr />
          {allproducts.map((product) => (
            <div key={product.id}>
              <div className="listproduct-format-main listproduct-format">
                <img
                  className="listproduct-product-icon"
                  src={product.image}
                  alt=""
                />
                <p cartitems-product-title>{product.name}</p>
                <p>${product.old_price}</p>
                <p>${product.new_price}</p>
                <p>{product.category}</p>
                <button onClick={() => handleEdit(product)}>Edit</button>
                <button onClick={() => removeProduct(product.id)}>
                  Delete
                </button>
              </div>
              <hr />
            </div>
          ))}
        </div>
      )}

      {/* Modal or form for editing the product */}
      {editProduct && (
        <div className="edit-product-modal">
          <h2>Edit Product</h2>
          <input
            type="text"
            name="name"
            value={editProduct.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="old_price"
            value={editProduct.old_price}
            onChange={handleChange}
          />
          <input
            type="text"
            name="new_price"
            value={editProduct.new_price}
            onChange={handleChange}
          />
          <input
            type="text"
            name="category"
            value={editProduct.category}
            onChange={handleChange}
          />
          <textarea
            name="description"
            value={editProduct.description}
            onChange={handleChange}
          />

          <button onClick={handleSave}>Save</button>
        </div>
      )}
    </div>
  );
};

export default ListProduct;
